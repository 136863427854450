<template>
  <div style="background-color: #FFFFFF; padding-bottom: 20px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="账户安全" name="account-security">
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin: 30px;">
      <el-col :span="24">
        <div class="as-item">
          <div class="as-item-content">
            <span class="title"><strong>登录密码</strong></span><br>
            <span class="desc">您已经设置了密码，建议您定期修改密码</span>
          </div>
          <div>
            <el-button type="primary" @click="isPasswordShow=true">修改</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 30px;">
      <el-col :span="24">
        <div class="as-item">
          <div class="as-item-content">
            <span class="title"><strong>手机号码</strong></span><br>
            <span class="desc">当前手机号码：{{ userInfo.mobile }}</span>
          </div>
          <div>
            <el-button type="primary" @click="isPhoneShow=true">修改</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 30px;">
      <el-col :span="24">
        <div class="as-item">
          <div class="as-item-content">
            <span class="title"><strong>真实姓名</strong></span><br>
            <span class="desc" v-if="userInfo.realName">{{ userInfo.realName }}</span>
            <span class="desc" v-if="!userInfo.realName">您还未设置真实姓名</span>
          </div>
          <div>
            <el-button type="primary" @click="isRealnameShow=true">{{userInfo.realName ? '修改' : '立即设置' }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 30px;">
      <el-col :span="24">
        <div class="as-item">
          <div class="as-item-content">
            <span class="title"><strong>公司邮箱</strong></span><br>
            <span class="desc" v-if="userInfo.enterpriseContactEmail">{{ userInfo.enterpriseContactEmail }}</span>
            <span class="desc" v-if="!userInfo.enterpriseContactEmail">您还未进行绑定邮箱</span>
          </div>
          <div>
            <el-button type="primary" @click="isEmailShow=true">{{userInfo.enterpriseContactEmail ? '修改' : '立即绑定' }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 30px;">
      <el-col :span="24">
        <div class="as-item">
          <div class="as-item-content">
            <span class="title"><strong>企业认证</strong></span><br>
            <span class="desc" v-if="userInfo.businessInformationStatus === '1'">已认证：{{ userInfo.enterpriseName }}</span>
            <span class="desc" v-if="userInfo.businessInformationStatus !== '1'">企业认证后可以进行数据维护</span>
          </div>
          <div>
            <el-button v-if="userInfo.businessInformationStatus === '1'" disabled>已认证</el-button>
            <el-button type="primary" v-if="userInfo.businessInformationStatus !== '1'" @click="handleCertification">立即认证</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <el-dialog v-model="isPasswordShow" title="修改密码" append-to-body="true" width="500px">
    <change-password :phone_No="userInfo.mobile" @closePasswordEmit="closePasswordEmit"></change-password>
  </el-dialog>
  <el-dialog v-model="isPhoneShow" title="修改手机号" append-to-body="true" width="500px">
    <change-phone @closePhoneEmit="closePhoneEmit"></change-phone>
  </el-dialog>
  <el-dialog @opened="changeRealNameOpened" v-model="isRealnameShow" title="修改真实姓名" append-to-body="true" width="500px">
    <change-realname ref="changeRealName" @closeRealnameEmit="closeRealnameEmit"></change-realname>
  </el-dialog>
  <el-dialog @opened="changeEmailOpened" v-model="isEmailShow" title="修改邮箱" append-to-body="true" width="500px">
    <change-email ref="changeEmail" @closeEmailEmit="closeEmailEmit"></change-email>
  </el-dialog>
</template>

<script>
import ChangePassword from '@/components/change-password/change-password'
import { ref } from 'vue'
import ChangePhone from '@/components/change-phone/change-phone'
import ChangeRealname from '../../components/change-realname/change-realname'
import ChangeEmail from '../../components/change-email/change-email'
export default {
  name: 'account-security',
  components: { ChangeEmail, ChangeRealname, ChangePhone, ChangePassword },
  data () {
    return {
      activeName: 'account-security',
      userInfo: JSON.parse(this.$store.getters.userInfo)
    }
  },
  watch: {
    '$store.getters.userInfo': function () {
      this.userInfo = JSON.parse(this.$store.getters.userInfo)
    }
  },
  mounted () {
  },
  methods: {
    handleCertification () {
      this.$router.push({ name: 'company-certification' })
      sessionStorage.removeItem('company-certification-name')
      sessionStorage.setItem('company-certification-name', 'account-security')
    },
    changeRealNameOpened () {
      this.$refs.changeRealName.opened()
    },
    changeEmailOpened () {
      this.$refs.changeEmail.opened()
    }
  },
  setup () {
    const isPasswordShow = ref(false)
    function closePasswordEmit () {
      isPasswordShow.value = false
    }

    const isPhoneShow = ref(false)
    function closePhoneEmit () {
      isPhoneShow.value = false
    }

    const isRealnameShow = ref(false)
    function closeRealnameEmit () {
      isRealnameShow.value = false
    }

    const isEmailShow = ref(false)
    function closeEmailEmit () {
      isEmailShow.value = false
    }
    return {
      isPasswordShow, closePasswordEmit, isPhoneShow, closePhoneEmit, isRealnameShow, closeRealnameEmit, isEmailShow, closeEmailEmit
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 120px;
}

.as-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.as-item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.as-item-content .title {
  font-size: 18px;
}

.as-item-content .desc {
  font-size: 14px;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
